<template>
  <ion-grid>
    <ion-row>
      <ion-col></ion-col>
      <ion-col :size-xl="xl" :size-lg="lg" :size-md="md" :size-sm="sm" :size-xs="xs">
        <slot></slot>
      </ion-col>
      <ion-col></ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import { IonGrid, IonRow, IonCol, } from "@ionic/vue";
export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
  },
  props: {
    wide: {
      type: Boolean,
      required: false,
      default: false,
    },
    widest: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    xl() {
      if (this.widest)
        return 10;
      else if (this.wide)
        return 10;
      return 5;
    },
    lg() { 
      if (this.widest)
        return 12;
      else if (this.wide)
        return 10;
      return 6;
    },
    md() { return 12; },
    sm() { return 12; },
    xs() { return 12; },
  },
};
</script>