<template>
  <slim-layout :title="title">
    <center-column>
      <ion-list class="info-list">
        <ion-item>
          <ion-icon color="medium" :icon="informationCircle" slot="start"></ion-icon>
          <ion-label>
            <div class="text-en">
              Custom software for educational businesses.
            </div>
            <!-- <div class="text-kr">
              한국어
            </div> -->
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-icon color="primary" :icon="call" slot="start"></ion-icon>
          <ion-label class="text-en">+82-10-3045-1358</ion-label>
        </ion-item>
        <ion-item>
          <ion-icon color="primary" :icon="mail" slot="start"></ion-icon>
          <ion-label class="text-en">smkn@esdikay.com</ion-label>
        </ion-item>
        <ion-item>
          <ion-icon color="medium" :icon="business" slot="start"></ion-icon>
          <ion-label class="text-en">
            <ion-text color="medium">business reg. no</ion-text>
            466-07-01076
          </ion-label>
        </ion-item>
      </ion-list>
    </center-column>
  </slim-layout>
</template>

<script>
import { IonList, IonItem, IonLabel, IonIcon, IonText, } from '@ionic/vue';
import { mail, business, call, informationCircle, } from "ionicons/icons";

import SlimLayout from "@/components/ui/SlimLayout.vue";
import CenterColumn from "@/components/ui/CenterColumn.vue";

export default {
  components: {
    IonList, IonItem, IonLabel, IonIcon, IonText,
    SlimLayout,
    CenterColumn,
  },
  data() {
    return {
      title: 'esdikay software',

      mail,
      business,
      call,
      informationCircle,
    };
  },
};
</script>

<style scoped>
  .info-list {
    margin-top: 4vh;
  }

  .text-en {
    font-family: 'IBM Plex Sans Condensed', sans-serif;
    font-size: 20px;
  }

  .text-kr {
    font-family: 'IBM Plex Sans KR', sans-serif;
    font-size: 20px;
  }
</style>
